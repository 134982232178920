import React from 'react';
// MATERIAL UI
import {
  Tab, Tabs, Typography, Box, AppBar, Grid,
} from '@material-ui/core';

// MATERIAL UI ICONS
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import PersonOutlineOutlinedIcon from '@material-ui/icons/PersonOutlineOutlined';
import ApartmentOutlinedIcon from '@material-ui/icons/ApartmentOutlined';

import BikfyReport from 'screens/Search/components/BikfyReport/BikfyReport';
import WorkshopBook from 'screens/Search/components/WorkshopBook/WorkshopBook';
import NotLoggedUserMessage from 'commons/components/NotLoggedUserMessage/NotLoggedUserMessage';
import useCustomTabsStyles from './customTabsStyles';
import CustomTab from './CustomTab';

const CustomsTabs = (props) => {
  const {
    bikfyReportBikeInfo,
    buyer,
    maintenances,
    handleGetFile,
    user,
    postedByBikfyStore,
    bikeMarketType,
  } = props;

  const classes = useCustomTabsStyles();
  const [value, setValue] = React.useState(0);

  const handleChangeTabs = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.generalTabsContainer}>
      <AppBar position="static" className="app-bar">
        <Tabs className="tabs" value={value} onChange={handleChangeTabs}>
          {postedByBikfyStore && <Tab className="tab" label="Beneficios de BIKFY" />}
          <Tab className="tab" label="Contacto" />
          <Tab className="tab" label="Informe BIKFY" />
          <Tab className="tab" label="Libro Taller" />
        </Tabs>
      </AppBar>
      <Box className="custom-tab-container">
        {postedByBikfyStore && (
          <CustomTab value={value} index={0} className="custom-tab">
            <Box className="benefits-tab-container">
              <Typography variant="h6" style={{ fontWeight: 'bold', marginBottom: '1rem' }}>
                Beneficios de BIKFY:
              </Typography>

              {/* Dynamic numbering */}
              {[
                {
                  title: 'Revisión y Certificación de Taller BIKFY:',
                  description: 'Nos aseguramos de que cada bicicleta cumpla con nuestros estándares de calidad.',
                },

                ...(bikeMarketType === 'refurbished'
                  ? [
                    {
                      title: 'Garantía Extendida:',
                      description: 'El segundo dueño también disfruta de la garantía del fabricante hasta al menos un año dependiendo de la marca.',
                    },
                  ]
                  : []),
                ...(true
                  ? [
                    {
                      title: 'Garantía para desperfectos Mecánicos:',
                      description: 'Tienes garantía mecánica en caso de cualquier desperfecto hasta por 60 días desde la compra en todo Chile.',
                    },
                  ]
                  : []),
                {
                  title: 'Política Crash Replacement para Motores Eléctricos en caso de fallas o accidentes.',
                  description: '',
                },
                {
                  title: 'Compra Protegida Online:',
                  description: 'Tienes hasta 24 horas desde que recibes tu bicicleta para poder devolverla en caso que lo desees.',
                },
              ].map((item, index) => (
                <Typography key={index} style={{ fontWeight: 'bold', marginTop: index > 0 ? '0.5rem' : 0 }}>
                  {`${index + 1}. ${item.title}`}
                  {item.description && (
                  <span style={{ fontWeight: 'normal' }}>
                    {' '}
                    {item.description}
                  </span>
                  )}
                </Typography>
              ))}

              <Box marginTop="1rem">
                <Typography style={{ fontWeight: 'normal' }}>
                  En BIKFY, creemos en la confianza y transparencia. Queremos que gastes menos y pedalees más, ¡con total tranquilidad!
                </Typography>
              </Box>

              <Box marginTop="1rem">
                <Typography>
                  <a href="/bikfylab-policy" style={{ textDecoration: 'underline' }}>
                    Conoce nuestra Política de venta y envíos
                  </a>
                </Typography>
              </Box>
            </Box>
          </CustomTab>
        )}
        <CustomTab value={value} index={postedByBikfyStore ? 1 : 0} className="custom-tab">
          <Grid
            container
            className="contact-info-tab-container"
            spacing={3}
            itemProp="seller"
            itemScope
            itemType="http://schema.org/Person"
          >
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <PersonOutlineOutlinedIcon />
              </Box>
              <Box>
                <Typography>Vendedor</Typography>
                <Typography itemProp="name">{buyer?.name}</Typography>
              </Box>
            </Grid>
            {user && (
              <Grid item md={6} xs={12} className="contact-info">
                <Box className="icon">
                  <MailOutlineIcon />
                </Box>
                <Box>
                  <Typography>Correo electrónico</Typography>
                  <Typography>{buyer?.email}</Typography>
                </Box>
              </Grid>
            )}
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <ApartmentOutlinedIcon />
              </Box>
              <Box>
                <Typography>Región</Typography>
                <Typography>{buyer?.region}</Typography>
              </Box>
            </Grid>
            <Grid item md={6} xs={12} className="contact-info">
              <Box className="icon">
                <ApartmentOutlinedIcon />
              </Box>
              <Box>
                <Typography>Comuna</Typography>
                <Typography>{buyer?.commune}</Typography>
              </Box>
            </Grid>
          </Grid>
        </CustomTab>
        <CustomTab value={value} index={postedByBikfyStore ? 2 : 1} className="custom-tab">
          <Box className="bikfy-report-tab-container">
            {user ? (
              <BikfyReport
                bike={{
                  ...bikfyReportBikeInfo,
                  currentOwner: buyer?.name,
                }}
                userType={user?.user?.type}
              />
            ) : (
              <NotLoggedUserMessage
                title="informe BIKFY"
                info="Podrás ver toda la información relacionada a la Bike, como su documentación, cantidad de dueños, entre otras cosas."
              />
            )}
          </Box>
        </CustomTab>
        <CustomTab value={value} index={postedByBikfyStore ? 3 : 2} className="custom-tab">
          <Box className="workshop-book-tab-container">
            {user ? (
              <WorkshopBook
                maintenances={maintenances}
                showGobackButton={false}
                handleGetFile={handleGetFile}
              />
            ) : (
              <NotLoggedUserMessage
                title="Libro Taller"
                info="Podrás ver toda la información relacionada a los servicios y mantenciones de la Bike"
              />
            )}
          </Box>
        </CustomTab>
      </Box>
    </Box>
  );
};

export default CustomsTabs;
