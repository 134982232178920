import React from 'react';
import {
  Box, Button, makeStyles, Tooltip, Typography,
} from '@material-ui/core';
import logoReport from 'commons/assets/bike-logo-report.svg';
import VisibilityIcon from '@material-ui/icons/Visibility';
import BlockIcon from '@material-ui/icons/Block';
import BeenhereIcon from '@material-ui/icons/Beenhere';
import certifiedIcon from 'commons/assets/gold.png';
import certifiedSilverIcon from 'commons/assets/silverCert.png';
import { BikesMarket } from 'models';
import styles from './styles';

const useStyles = makeStyles(styles);

const BikeImage = (props) => {
  const {
    reported, openModal, url, style, isDisabled, postedByBikfyStore, bikeMarket,
  } = props;

  const classes = useStyles();

  const isReportedOrDisabled = reported || isDisabled;

  const handleModal = (e) => {
    e.preventDefault();
    e.stopPropagation();
    openModal();
  };

  if (url) {
    return (
      <Box style={{ ...style }} display="flex" className={classes.root} onClick={handleModal}>
        <img
          src={url}
          alt="Foto"
          className={`${classes.img} ${isReportedOrDisabled ? classes.blur : ''}`}
          loading="lazy"
        />
        {postedByBikfyStore && (
        <Box
          style={{
            position: 'absolute',
            top: 35,
            left: 0,
            zIndex: 1,
          }}
        >
          <Tooltip
            title={bikeMarket.type === 'refurbished' ? 'Semi-nueva certificada' : 'Certificada'}
            placement="top"
            arrow
          >
            <img
              src={bikeMarket.type === 'refurbished' ? certifiedIcon : certifiedSilverIcon}
              alt="Custom Icon"
              className={classes.icon}
              style={{
                width: 45, // Set either width or height, but not both to maintain aspect ratio
                height: 'auto', // Use 'auto' to maintain the original aspect ratio
              }}
            />
          </Tooltip>
        </Box>
        )}

        {isReportedOrDisabled ? (
          <Box className={classes.rootReport}>
            <img src={logoReport} style={{ height: '70%' }} />
            <Typography variant="h6" className={`${classes.reportText} color-danger`}>
              <Box fontWeight="fontWeightBold">
                BIKE
                {reported ? 'DENUNCIADA' : 'INHABILITADA'}
              </Box>
            </Typography>
          </Box>
        ) : (
          <>
            <Box className={classes.eye}>
              <VisibilityIcon className={classes.icon} color="primary" style={{ fontSize: 60 }} />
              <Button variant="outlined" size="small" color="primary" className={classes.btn}>
                ver fotos
              </Button>
            </Box>
          </>
        )}
      </Box>
    );
  }
  return (
    <Box display="flex" className={classes.root}>
      <BlockIcon className={classes.icon} style={{ fontSize: 200 }} />
    </Box>
  );
};

export default BikeImage;
