import React, { useEffect, useMemo, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { Add, PhotoLibrary } from '@material-ui/icons';
import {
  Card, CardActions, FormControl, FormHelperText, Grid,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import {
  blobToFile,
  fileToImageAsync,
  getCroppedBlobAndImageAsync,
  getDefaultCrop,
} from 'utils/functions';
import CroppedImageModal from '../CroppedImageModal';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      align: 'center',
      justify: 'center',
    },
  },
  input: {
    display: 'none',
  },
  button: {
    margin: theme.spacing(1),
    backgroundColor: '#fff',
  },
  newButton: {
    width: '180px',
    margin: theme.spacing(1),
    // backgroundColor: '#fff',
  },
}));

const CustomInputFile = ({
  title,
  id,
  name,
  onChange,
  error,
  errors,
  value,
  documentType,
  url = '',
  disabled,
  reset,
  accept = 'image/*',
  crop,
}) => {
  const [newFile, setNewFile] = useState(false);
  const [fileUrl, setFileUrl] = useState('');

  const [image, setImage] = useState(null);
  const [initialCrop, setInitialCrop] = useState(null);
  const [isNewCropImage, setIsNewCropImage] = useState(false);
  const [open, setOpen] = useState(false);

  const classes = useStyles();

  useEffect(() => {
    if (url) setFileUrl(url);
  }, [url]);

  const handleChange = async (e) => {
    const currentFile = e.target.files[0];

    let croppedFile = null;
    setNewFile(true);
    setFileUrl(URL.createObjectURL(currentFile));

    if (crop) {
      const compresedImage = await fileToImageAsync(currentFile);
      const defaultCrop = getDefaultCrop(compresedImage);
      const { croppedBlob, croppedImage } = await getCroppedBlobAndImageAsync(
        compresedImage,
        defaultCrop,
      );
      croppedFile = blobToFile(croppedBlob, `${name}.jpg`);

      setFileUrl(URL.createObjectURL(croppedFile));

      setInitialCrop(defaultCrop);
      setImage(compresedImage);
      setIsNewCropImage(true);
    }

    const newValue = croppedFile ?? currentFile;

    onChange({ target: { name, value: newValue } });
    onChange({ target: { name: documentType, value: 0 } });

    // Open cut modal.
    setOpen(true);
  };

  const resetFile = () => {
    onChange({ target: { name, value: '' } });
    onChange({ target: { name: documentType, value: 0 } });
    setNewFile(true);

    if (crop) {
      setImage(null);
    }
  };

  const onConfirmCrop = async (crop) => {
    const { croppedBlob, croppedImage } = await getCroppedBlobAndImageAsync(image, crop);

    const newCroppedFile = blobToFile(croppedBlob, `${name}.jpg`);

    onChange({ target: { name, value: newCroppedFile } });
    onChange({ target: { name: documentType, value: 0 } });
    setFileUrl(URL.createObjectURL(newCroppedFile));
    setIsNewCropImage(false);
  };

  const fileExtension = useMemo(() => value?.name?.split('.')?.pop().toLowerCase(), [value]);

  return (
    <div className={classes.root}>
      <FormControl>
        <input
          id={id}
          className={classes.input}
          type="file"
          multiple
          accept={accept}
          onChange={handleChange}
          onClick={(event) => {
            event.target.value = null;
          }}
          disabled={disabled}
        />
        <label htmlFor={id}>
          <Button color="primary" component="span" variant="contained" disabled={disabled}>
            <Grid container>
              <Grid container item xs={12} justifyContent="center">
                <AddIcon />
              </Grid>
              <Grid container item xs={12} justifyContent="center">
                {title}
              </Grid>
            </Grid>
          </Button>
        </label>

        <FormHelperText align="center" style={{ color: 'red' }}>
          {!error ? '' : errors}
        </FormHelperText>
      </FormControl>
      {value?.name && (
        <>
          <div>
            {url && !newFile ? (
              <a
                href={url}
                target="_blank"
                style={{
                  textDecoration: 'none',
                  color: '#000',
                  fontFamily: 'Mark Pro',
                }}
                rel="noreferrer"
              >
                {value?.name}
              </a>
            ) : (
              value?.name
            )}
            <IconButton disabled={disabled} onClick={resetFile}>
              <ClearIcon />
            </IconButton>
          </div>
          {!!fileUrl && (
            <>
              {fileExtension === 'pdf' ? (
                <iframe
                  src={`${fileUrl}#toolbar=0&navpanes=0&scrollbar=0`}
                  height="390"
                  width="300"
                  frameBorder="0"
                />
              ) : (
                <Card
                  style={{
                    fontSize: 0,
                    display: 'flex',
                    position: 'relative',
                    justifyContent: 'center',
                    alignItems: 'flex-end',
                  }}
                >
                  <img
                    src={fileUrl}
                    style={{
                      maxWidth: '100%',
                      maxHeight: '300px',
                      ...(crop
                        ? {
                          minHeight: '300px',
                        }
                        : {}),
                    }}
                    alt=""
                  />
                  <CardActions style={{ position: 'absolute' }}>
                    {crop && initialCrop && (
                      <Button variant="contained" color="primary" onClick={() => setOpen(true)}>
                        <EditIcon />
                      </Button>
                    )}
                    <Button variant="contained" color="secondary" onClick={resetFile}>
                      <DeleteIcon />
                    </Button>
                  </CardActions>
                </Card>
              )}
            </>
          )}
        </>
      )}

      {crop && initialCrop && (
        <CroppedImageModal
          image={image}
          show={open}
          onClose={() => {
            setOpen(false);
          }}
          onConfirmCrop={onConfirmCrop}
          initialCrop={initialCrop}
          isNewCropImage={isNewCropImage}
        />
      )}
    </div>
  );
};

export default CustomInputFile;
